import { createApp } from "vue";
import { createPinia } from "pinia";
import VueGoodTablePlugin from "vue-good-table-next";
import FloatingVue from "floating-vue";
import AnnounceKit from "announcekit-vue";

// import the styles
import "vue-good-table-next/dist/vue-good-table-next.css";
import "floating-vue/dist/style.css";

import VueCookies from "vue-cookies";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons not part of the default set */
// import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
library.add(faLinkedinIn);

import App from "./app.vue";
import router from "./router";
import store from "./store";
import utils from "@/shared/utils";
import "./registerServiceWorker";
// import "@/assets/styles/bootstrap.css";
import "@/assets/styles/bootstrap-customizations.css";
import "@/assets/js/bootstrap.js";

import * as Sentry from "@sentry/vue";

import filters from "./shared/globalProperties/filters";

import * as $ from "jquery";

import interceptors from "./shared/interceptors";
(window as any).$ = $;

const appVersionObject = require("../../build.json");
console.info("AppVersion : ", appVersionObject, utils.getAppVersion());
const appVersion = appVersionObject.FE ? appVersionObject.FE : null;
const isDev = process.env.VUE_APP_MODE === "development";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
interceptors();

app.use(router);
app.use(store);
app.use(VueGoodTablePlugin);
app.use(VueCookies);
app.use(FloatingVue);
app.use(AnnounceKit);
app.component("font-awesome-icon", FontAwesomeIcon);

if (isDev) {
  console.info("disabling raven/sentry to see error");
} else {
  Sentry.init({
    app,
    dsn: "https://ba7eca18531be0aa131cac3e2ff44413@sentry2.idatalabs.com/5",
    // @ts-ignore (for some reason TS is not getting the types correctly)
    integrations: [Sentry.browserTracingIntegration({ router }), Sentry.browserProfilingIntegration(), Sentry.httpClientIntegration()],
    tracesSampleRate: 1.0,
    environment: process.env.VUE_APP_MODE,
    release: appVersion,
    ignoreErrors: [
      // Router errors with no impact
      "/Navigation cancelled from/",
      "/Redirected when going from/",
      // vue-good-table errors with no impact
      "'ResizeObserver' is not defined",
      "Unable to get property 'disconnect' of undefined or null reference",
      "Can't find variable: ResizeObserver",
    ],
    // tracePropagationTargets: [/https:\/\/.*\.(enlyft|idatalabs)\.com/],
  });
}

/** filters. TODO: move into separate file. This was required for vue 3 since filters are no longer valid.
 * A better way of solving this is to use composition api and functions, but this is a reasonable solution found here
 * https://v3-migration.vuejs.org/breaking-changes/filters.html#global-filters
 */
app.config.globalProperties.$filters = filters;

app.mount("#app");

/** Directives.
 * TODO: move to separate file
 */
app.directive("focus", {
  mounted: function (el) {
    if (!el) {
      return;
    }
    el.focus();
  },
});

app.directive("click-outside", {
  beforeMount(el: any, binding: any, vnode: any) {
    if (!el) {
      return;
    }
    el.handleOutsideClick = (e: any) => {
      e.stopPropagation();
      let handler: any = undefined;
      let exclude = [];
      if (typeof binding.value === "object") {
        handler = binding.value.handler;
        exclude = binding.value.exclude;
      } else if (typeof binding.value === "function") {
        handler = binding.value;
      } else {
        console.error("unknown closable binding", binding.value, typeof binding.value);
      }
      let clickedOnExcludedEl = false;
      if (exclude) {
        if (!Array.isArray(exclude)) {
          exclude = [exclude];
        }
        exclude.forEach((key: string) => {
          if (!clickedOnExcludedEl) {
            if (key[0] === ".") {
              const excludedEls = document.getElementsByClassName(key.substring(1));
              // also checks if parent or grand parent of target contains excluded classes
              if (e?.target?.classList?.contains(key.substring(1)) || e?.target.parentElement?.classList?.contains(key.substring(1)) || e?.target.parentElement?.parentElement?.classList?.contains(key.substring(1)))
                clickedOnExcludedEl = true;
              if (excludedEls && !clickedOnExcludedEl) {
                Array.from(excludedEls).forEach((el: any) => {
                  if (clickedOnExcludedEl || el.contains(e.target)) {
                    clickedOnExcludedEl = true;
                  }
                });
              }
            } else if (key[0] === "#") {
              const excludedEl = document.getElementById(key.substring(1));
              if (excludedEl) {
                clickedOnExcludedEl = excludedEl.contains(e.target);
              }
            } else if (key[0] === "$") {
              const excludedElRef = vnode.context.$refs[key.substring(1)];
              if (excludedElRef) {
                clickedOnExcludedEl = excludedElRef.contains(e.target);
              }
            } else {
              console.error("closable excludes should start with '#', '.' or '$'");
            }
          }
        });
      }
      if (el && !el.contains(e.target) && !clickedOnExcludedEl) {
        if (typeof handler === "string") {
          vnode.context[handler]();
        } else if (typeof handler === "function") {
          handler(e);
        } else {
          console.error("unknown closable handler", handler, typeof handler);
        }
      }
    };
    document.addEventListener("click", el.handleOutsideClick);
    document.addEventListener("touchstart", el.handleOutsideClick);
  },
  unmounted(el: any) {
    if (!el || !el.handleOutsideClick) {
      return;
    }
    document.removeEventListener("click", el.handleOutsideClick);
    document.removeEventListener("touchstart", el.handleOutsideClick);
  },
});

// Add 'store' to the window object only when testing with Cypress
if ((window as any).Cypress) {
  (window as any).store = store;
}

// To fix "Property 'msSaveBlob' does not exist on type 'Navigator'" Typescript error
declare global {
  interface Navigator {
    msSaveBlob: (blob: Blob, fileName: string) => boolean;
  }

  interface Window {
    Intercom: any;
  }
}
